(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/dialog-adapter/assets/javascripts/helper.js') >= 0) return;  svs.modules.push('/components/content/dialog-adapter/assets/javascripts/helper.js');
"use strict";


const Type = {
  INFO: 'info',
  ERROR: 'error',
  INFOERROR: 'infoerror',
  DEFAULT: 'info'
};
svs.components.dialogAdapter.helper = {
  Type
};

 })(window);