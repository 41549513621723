(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/dialog-adapter/assets/javascripts/message-box.js') >= 0) return;  svs.modules.push('/components/content/dialog-adapter/assets/javascripts/message-box.js');
"use strict";


const {
  dialog
} = svs.ui;
const {
  Type
} = svs.components.dialogAdapter.helper;
const {
  button,
  cmsType,
  icon,
  newDialog,
  text
} = svs.components.dialogWrapper.helper;

const convertTypeToCmsType = type => {
  switch (type) {
    case Type.INFO:
      return cmsType.INFO;
    case Type.ERROR:
      return cmsType.ERROR;
    default:
      return cmsType.INFO;
  }
};

const convertToMBButtons = buttons => buttons ? buttons.map(_ref => {
  let {
    callback,
    text
  } = _ref;
  return button({
    callback,
    title: text
  });
}) : [];

const show = _ref2 => {
  var _dialog$api;
  let {
    buttons,
    message,
    title,
    type
  } = _ref2;
  if ((dialog === null || dialog === void 0 || (_dialog$api = dialog.api) === null || _dialog$api === void 0 || (_dialog$api = _dialog$api.queue) === null || _dialog$api === void 0 || (_dialog$api = _dialog$api.areas) === null || _dialog$api === void 0 || (_dialog$api = _dialog$api.popup) === null || _dialog$api === void 0 ? void 0 : _dialog$api.count()) > 0) {
    return;
  }
  const convertedType = convertTypeToCmsType(type);
  newDialog({
    area: dialog.area.POPUP,
    buttons: convertToMBButtons(buttons),
    icon: icon[convertedType],
    message: [text({
      text: message || ''
    })],
    title,
    type: "".concat(convertedType, " message-box-message")
  });
};
svs.components.MessageBox = {
  ButtonType: {},
  show,
  Type
};

 })(window);