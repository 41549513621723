(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/dialog-adapter/assets/javascripts/prescript.js') >= 0) return;  svs.modules.push('/components/content/dialog-adapter/assets/javascripts/prescript.js');

svs.components = svs.components || {};
svs.components.dialogAdapter = svs.components.dialogAdapter || {};

svs.content = svs.content || {};
svs.content.dialogAdapter = svs.content.dialogAdapter || {};



 })(window);