(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/dialog-adapter/assets/javascripts/alert.js') >= 0) return;  svs.modules.push('/components/content/dialog-adapter/assets/javascripts/alert.js');
"use strict";

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  dialog
} = svs.ui;
const {
  button,
  cmsType,
  icon,
  newDialog
} = svs.components.dialogWrapper.helper;
const {
  Type
} = svs.components.dialogAdapter.helper;

const convertTypeToCmsType = type => {
  switch (type) {
    case Type.ERROR:
      return cmsType.ERROR;
    default:
      return cmsType.CONFIRM;
  }
};

class Alert {
  constructor() {
    let message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    this.title = message;
    this.time = options.time || 0;
    this.type = convertTypeToCmsType(options.type || Type.DEFAULT);
    this.icon = icon[this.type];
    this.convertToPopup = options.convertToPopup || false;
  }


  show() {
    newDialog({
      area: dialog.area.TOASTER,
      autoClose: this.time,
      buttons: [button({
        title: 'Okej',
        callback: () => {
          var _this$dialog;
          return (_this$dialog = this.dialog) === null || _this$dialog === void 0 ? void 0 : _this$dialog.close();
        }
      })],
      convertToPopup: this.convertToPopup,
      icon: this.icon,
      title: this.title,
      type: "".concat(this.type, " alert-wrapper")
    });
  }
}
_defineProperty(Alert, "Time", {
  SHORT: 3000,
  MEDIUM: 5000,
  LONG: 10000,
  VERY_LONG: 19000,
  DEFAULT: 10000
});
_defineProperty(Alert, "Type", Type);
svs.components.Alert = Alert;

 })(window);